import { getCookie } from '../auth/cookie'

export const getCreatableRoles = currentRole => {
  return (
    {
      1: [2],
      2: [3, 4, 5],
      3: [6],
      4: [],
      5: [7],
      6: [],
      7: []
    }[currentRole] || []
  )
}

export const getReadableRoles = currentRole => {
  return (
    {
      1: [2, 3, 4, 5, 6, 7],
      2: [3, 4, 5, 6, 7],
      3: [6],
      4: [],
      5: [7],
      6: [],
      7: []
    }[currentRole] || []
  )
}

export const getCreatableUserDomains = currentRole => {
  const EMAIL_DOMAINS = {
    empower: '@empower.me',
    oriente: '@oriente.com',
    cashalo: '@cashalo.com',
    finmas: '@finmas.co.id',
    finizi: '@finizi.vn',
    slash: '@finmas.co.id' // TODO add slash domain
  }
  const roleAllowedDomains =
    {
      1: Object.values(EMAIL_DOMAINS)
    }[currentRole] || []
  const companyId = getCookie('oCompany')
  const companyAllowedDomains =
    {
      2: [EMAIL_DOMAINS.oriente, EMAIL_DOMAINS.cashalo, EMAIL_DOMAINS.empower],
      3: [EMAIL_DOMAINS.oriente, EMAIL_DOMAINS.finmas],
      4: [EMAIL_DOMAINS.oriente, EMAIL_DOMAINS.finizi],
      5: [EMAIL_DOMAINS.oriente, EMAIL_DOMAINS.slash]
    }[companyId] || []
  return roleAllowedDomains.concat(companyAllowedDomains)
}
