import { getCreatableUserDomains } from '../auth/config'
import { toDateObj } from './date'

export const oneOf = list => {
  const set = list instanceof Set ? list : new Set(list)
  return function (str) {
    return (
      (!!str && set.has(str)) ||
      `${this.$t('validation.not_one_of')}: ${Array.from(set)}`
    )
  }
}

export const gt = function (min) {
  return n => {
    const number = typeof n === 'number' ? n : Number(n)
    return (
      (!isNaN(number) && number > min) ||
      this.$t('validation.number.gt', { number: min })
    )
  }
}

export const gte = function (min) {
  return n => {
    const number = typeof n === 'number' ? n : Number(n)
    return (
      (!isNaN(number) && number >= min) ||
      this.$t('validation.number.gte', { number: min })
    )
  }
}

export const integer = function (s) {
  return /^\d+$/.test(s) || this.$t('validation.number.integer')
}

export const integerGt = function (min) {
  return n => {
    const isInt = integer.call(this, n) === true
    const isGt = gt.call(this, min)(n) === true
    return (
      (isInt && isGt) || this.$t('validation.number.integerGt', { number: min })
    )
  }
}

export const integerGte = function (min) {
  return n => {
    const isInt = integer.call(this, n) === true
    const isGte = gte.call(this, min)(n) === true
    return (
      (isInt && isGte) ||
      this.$t('validation.number.integerGte', { number: min })
    )
  }
}

export const notEmpty = function (text) {
  const str = text && text.toString && text.toString()
  return (!!str && str.trim().length > 0) || this.$t('validation.required')
}

export const maxLength = length =>
  function (text) {
    return (
      (typeof text !== 'undefined' && text.trim().length <= length) ||
      `${this.$t('validation.too_long')}: ${length}`
    )
  }

export const minLength = length =>
  function (text) {
    return (
      (!!text && text.trim().length >= length) ||
      `${this.$t('validation.too_short')}: ${length}`
    )
  }

export const isUrl = function (text) {
  try {
    text = text.trim()
    const url = new URL(text)
    if (url && /^http(s)?:\/\//.test(text)) return true
    throw new Error('Url is invalid')
  } catch (e) {
    return this.$t('validation.incorrect_format.url')
  }
}

export const hasUserIdQueryString = function (text) {
  try {
    text = text.trim()
    const url = new URL(text)
    if (url && /={{userId}}/.test(text)) return true
    throw new Error(
      'Url is invalid. Please insert {{userId}} in URL query string'
    )
  } catch (e) {
    return this.$t('validation.incorrect_format.user_id')
  }
}

export const isEmail = function (text) {
  return (
    (!!text &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        text
      )) ||
    this.$t('validation.incorrect_format.email')
  )
}

export const isValidMobile = countryCode => {
  const regex = {
    '886': /^0?(9[0-9]{8})$/,
    '63': /^0?(9[0-9]{8,9})$/,
    '65': /^([8|9][0-9]{7})$/,
    '62': /^\d+$/,
    '84': /^\d+$/
  }[countryCode]
  if (!regex) {
    throw new Error('Invalid countryCode')
  }
  return function (text) {
    return (
      (!!text && regex.test(text)) ||
      this.$t('validation.incorrect_format.mobile')
    )
  }
}

export const validEmailDomain = currentUserRole => {
  const allowedDomains = new Set(getCreatableUserDomains(currentUserRole))
  return function (text) {
    const email = text.toLowerCase()
    const match = email.match(/@.+$/)
    return (
      (!!email && !!match && allowedDomains.has(match[0])) ||
      this.$t('validation.incorrect_format.email_domain')
    )
  }
}

export const passwordFormat = function (text) {
  return (
    (!!text &&
      /^(?!.*[^a-zA-Z0-9])(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/.test(text)) ||
    this.$t('validation.incorrect_format.password')
  )
}

// This is not for v-form validation
export const greaterThanCurrentTime = function (isoString, dHour = 0) {
  if (
    toDateObj() >=
    new Date(toDateObj(isoString).valueOf() - dHour * 60 * 60 * 1000)
  ) {
    throw dHour === 0
      ? this.$t('Task.form.execute_time_before_current_time_error', {
        hour: dHour
      })
      : this.$t('Task.form.execute_time_error', { hour: dHour })
  }
}
