import { gt, gte, notEmpty, integerGt, integerGte } from '@/utils/validation'

export default {
  methods: {
    gt,
    gte,
    notEmpty,
    integerGt,
    integerGte
  }
}
