<template lang="pug">
  .contentWholeWrap
    v-layout.mb-4(row wrap)
      h2.headline.font-weight-medium
        | Frequency Control
    .contentBoxWrap
      .contentBox
        .frequencyWrap
          section.frequencyType(v-for='(setting, channel) in settings' :key="channel")
            h3.title {{ $t(`Task.channel.${channel}`) }}
            div
              .formWrap(v-for='item in setting.rules')
                .formInline
                  v-switch.shrink.mr-2.mt-0(v-model='item.active' hide-details)
                  span(:class='{ active: item.active }') No more than
                  x-textfield.pt-0.small(
                    ref="input"
                    required
                    :disabled='!item.active'
                    v-model="item.value"
                    :rules="[notEmpty, integerGt(0)]"
                  )
                  span.channel(:class='{ active: item.active }')
                    | {{ channel }} in
                    |
                    span.title {{ item.duration }}
                p.error--text {{ item.errorMessage }}
          x-button.btnSecondary(v-bind='scheme.buttonSubmit')
</template>

<script>
import { getCompanyConfig } from '@/const'
import validation from '@/mixins/validation'

export default {
  mixins: [validation],
  data () {
    const settings = this.getDefaultSettings()
    return {
      settings,
      scheme: {
        buttonSubmit: {
          content: 'Save',
          size: 'large',
          click: this.submit
        }
      }
    }
  },
  watch: {
    settings: {
      handler (val) {
        let ind = 0
        Object.values(this.settings).forEach(channel => {
          channel.rules.forEach(duration => {
            const errorMessage = (() => {
              if (!duration.active) return null
              const validateResult = this.$refs.input[ind].rules
                .map(rule => rule(duration.value))
                .filter(x => x !== true)[0]
              return validateResult || null
            })()
            duration.errorMessage = errorMessage
            ind++
          })
        })
      },
      deep: true
    }
  },
  created () {
    this.fetchContactRules()
  },
  methods: {
    async fetchContactRules () {
      this.$store.loading = true
      try {
        const {
          data: { contactRules }
        } = await this.$Frequency.fetchContactRules()
        contactRules.forEach(({ id, channel, rules }) => {
          const rulesMap = new Map(
            this.settings[channel].rules.map(rule => [rule.duration, rule])
          )
          rules.forEach(({ days, limit }) => {
            rulesMap.set(days, {
              duration: days,
              active: true,
              value: String(limit) // Value of `x-textfield` must be string
            })
          })
          this.settings[channel] = {
            id,
            rules: Array.from(rulesMap)
              .sort((a, b) => a[0] > b[0])
              .map(x => x[1])
          }
        })
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    getDefaultSettings () {
      const { taskChannels } = getCompanyConfig({ env: this.$store.env })
      const durations = [1, 7, 30]
      const settings = {}
      taskChannels.forEach(channel => {
        const rules = durations.map(duration => ({
          duration,
          active: false,
          value: null
        }))
        settings[channel] = {
          id: null,
          rules
        }
      })
      return settings
    },
    getSubmitData (channel) {
      const { id, rules } = this.settings[channel]
      return {
        id,
        channel,
        rules: rules
          .filter(({ active }) => active)
          .map(({ duration, value }) => ({
            days: duration,
            limit: Number(value)
          }))
      }
    },
    validate () {
      return Object.values(this.settings).every(({ rules }) => {
        return rules.every(({ errorMessage }) => !errorMessage)
      })
    },
    async submit () {
      if (!this.validate()) return
      this.$store.loading = true
      try {
        await Promise.all(
          Object.keys(this.settings).map(channel => {
            const data = this.getSubmitData(channel)
            if (data.id) return this.$Frequency.updateContactRule(data)
            return this.$Frequency.createContactRule(data)
          })
        )
        this.$store.successNotification =
          'Frequency Control is successfully updated'
        await this.fetchContactRules()
      } catch (e) {
        this.$store.loading = false
        this.$store.errorNotification = e
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.frequencyWrap {
  @media (min-width:960px) {
    width: 680px;
    margin: 0 auto;
  }
  .frequencyType {
    margin: 10px 0 30px;
    border-bottom: $thin-solid;
    @media (min-width:960px) {
      display: flex;
    }
    h3 {
      width: 200px;
      padding: 10px 0;
      flex: 0 1 auto !important;
    }
      .formWrap {
        .formInline {
          display: flex;
          flex-wrap: wrap;
          flex: 1 1 auto;
          align-items: center;
         >>> .v-text-field__details {
            display: none;
          }
          .small {
            flex: 0;
            margin: auto 10px;
           >>> .v-input__control {
              width: 50px;
              flex-grow: 0;
            }
          }
         > span {
            color: $silver;
            &.active {
              color: $black;
            }
          }
        }
        p.error--text {
          height: 20px;
          margin-bottom: 10px;
        }
      }
  }
}
.formInline {
  span.channel::after {
    content: " days.";
    .formWrap:first-child & {
      content: " day.";
    }
  }
}
</style>
